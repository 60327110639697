// src/App.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { useMediaQuery } from "react-responsive";
import List from "./List";
import Filters from "./Filters";
import { getQueryParams, getInitialFilter, fetchFiltered } from "./utils/Common.jsx"
import { defaultValues, cityNames } from "./utils/Values.jsx"
import "./Search.css";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const App = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const [articles, setArticles] = useState([]);
    const [cursor, setCursor] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(true);
    const initialLoadCompleteRef = useRef(false);

    const query = useQuery();
    const navigate = useNavigate()
    const [filters, setFilters] = useState({
        rooms: getInitialFilter(query, 'rooms', defaultValues.rooms),
        minPriceUsd: getInitialFilter(query, 'minPriceUsd', defaultValues.minPriceUsd),
        maxPriceUsd: getInitialFilter(query, 'maxPriceUsd', defaultValues.maxPriceUsd),
        isOwner: getInitialFilter(query, 'isOwner', defaultValues.isOwner),
        city: getInitialFilter(query, 'city', defaultValues.city)
    });

    const updateFilter = (field, value) => {
        const updatedFilters = {
            ...filters,
            [field]: value
        };
        setFilters(updatedFilters);

        const params = getQueryParams(updatedFilters);
        navigate({
            pathname: window.location.pathname,
            search: params.toString(),
        }, { replace: true });
    };
    
    useEffect(() => {
        var city = cityNames[filters.city]
        document.title = `Снять квартиру ${city ? `в ${city}` : ``} на длительный срок`;
        document.description = `Снять квартиру ${city ? `в ${city}` : ``} в Минске. Квартиры на длительный срок ${city ? `в ${city}` : ``}`;
      }, [filters]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchFiltered(filters);
            if(response.data) {
                setArticles(response.data.articles.items);
                setCursor(response.data.articles.endCursor)
                setHasNextPage(response.data.articles.hasNextPage)
            }
            initialLoadCompleteRef.current = true;
        };
        fetchData();
    }, [filters]);

    const paginate = useCallback(async () => {
        if(hasNextPage) {
            const response = await fetchFiltered(filters, cursor);
            setArticles((prevItems) => [
                ...prevItems,
                ...response.data.articles.items,
            ]);
            if(response.data) {
                setCursor(response.data.articles.endCursor)
                setHasNextPage(response.data.articles.hasNextPage)
            }
        }
    }, [filters, cursor, hasNextPage]);

    return (
        <div className="search-container">
            <Filters filters={filters} updateFilter={updateFilter} />
            <div className="app">
                <div className={`content-container ${isMobile ? "" : "desktop"}`}>
                    <List
                            articles={articles}
                            initialLoadCompleteRef={initialLoadCompleteRef}
                            paginate={paginate}
                    />
                </div>
            </div>
        </div>
    );
};

export default App;
