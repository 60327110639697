export const rooms = ['1', '2', '3', '4+', 'ком.'];


export const cities = [
    'Минск',
    'Брест',
    "Витебск",
    "Гомель",
    "Гродно",
    "Могилёв",
    "Бобруйск",
    "Борисов",
    "Барановичи",
    "Орша"
]

export const cityNames = {
    'Минск' : 'Минске',
    'Брест' : 'Бресте',
    "Витебск" : 'Витебске',
    "Гомель" : 'Гомеле',
    "Гродно" : 'Гродно',
    "Могилёв" : 'Могилёве',
    "Бобруйск" : 'Бобруйске',
    "Борисов" : 'Борисове',
    "Барановичи" : 'Барановичах',
    "Орша" : 'Орше'
}

export const defaultValues = {
    city: 'Минск',
    rooms: [],
    minPriceUsd: '',
    maxPriceUsd: '',
    isOwner: false
};