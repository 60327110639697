import React from 'react';
import './Header.css';
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
          <Link to="." className="logo" style={{ textDecoration: 'none', alignItems: 'center' }}>Infoflat.by</Link>
    </div>
  );
};

export default Header;