import { useEffect,  useRef } from "react";
import { timeDifference } from "./utils/Common.jsx";
import { Link } from "react-router-dom";
import "./List.css";

export const Listing = ({ listing }) => {
    return (
        <Link to={`${listing.id}`} 
                className="listing"
            style={{ textDecoration: 'none' }}
            >
                <div className="listing-img">
                    <img
                        loading="lazy" 
                        src={listing.avatar ?? "error.jpg"}
                        alt={listing.rooms}
                        className="listing-image"
                        onError={({ currentTarget }) => {
                            console.log("lalalal")
                            currentTarget.onerror = null;
                            currentTarget.src="error.jpg";
                          }}
                    />

                    <div className="listing-content">
                        <span className="offer-type">
                            {listing.isOwner ? "Собственник" : "Агентство"}
                        </span>
                        <span className="offer-time">
                            <span className="property-icon icon-timer"></span> {timeDifference(listing.lastUp)}
                        </span>

                        <span className="offer-price">
                            {listing.priceUsd > 0 ? `$${listing.priceUsd}` : `Договорная`}
                        </span>
                    </div>
                </div>
                <p className="listing-address"><span className="listing-rooms">{listing.rooms === 0 ? `` : listing.rooms} ком.</span><span className="address-icon"></span>{listing.address}</p>
        </Link>
    );
};

const List = ({ articles, initialLoadCompleteRef, paginate}) => {

    const bottomRef = useRef(null);

    useEffect(() => {
        const bottom = bottomRef.current
        var observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && initialLoadCompleteRef.current) {
                paginate()
            }
        }, {
            threshold: 1.0,
        });

        if (bottomRef.current) {
            observer.observe(bottomRef.current);
        }

        return () => {
            if (bottom) {
                observer.unobserve(bottom);
            }
        };
    }, [paginate, initialLoadCompleteRef]);

    return (
        <div className="listings">
            {articles.map((listing, index) => (
                <Listing
                    key={index}
                    listing={listing}
                />
            ))}
            <div ref={bottomRef} style={{ height: '20px' }} />
        </div>
    );
};

export default List;
