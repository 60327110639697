// src/App.js
import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from "./components/Header";
import Search from "./components/Search";
import Item from "./components/Item";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {


    return (
       <div className = "wrapper">
                <BrowserRouter>
                    <Header />
                    <Routes>
                        <Route exact path="/:id" element={<Item />}></Route>
                        <Route path="/" element={<Search />}></Route>
                    </Routes>
                </BrowserRouter>
    </div>
  );
};

export default App;
